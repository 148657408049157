import React, { Component } from 'react';
import {connect } from 'react-redux';

class Footer extends Component {
   
    render() {
        
        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }

        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }
    return ( 
        <footer className="main-footer">
            <div className="footer-upper">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="big-column col-md-6 col-sm-12 col-xs-12">
                            <div className="row clearfix">
                                <div className="footer-column col-md-7 col-sm-6 col-xs-12">
                                    <div className="footer-widget about-widget">
                                        <div className="widget-inner">
                                            <div className="logo"><img src="images/footer-logo.png" alt=""/></div>
                                            <div className="text" style={hideEnglish}>La Coopérative des expatriés est une personne morale qui a pour but la satisfaction des besoins économiques, sociaux ou culturels communs, des membres de la communauté. Elle est une coopérative de consommateurs.</div>
                                            <div className="text" style={hideFrench}>The Expatriate Cooperative is a legal person whose goal is to meet the common economic, social or cultural needs of the members of the community. It is a consumer cooperative.</div>
                                            <div className="social-links">
                                                <ul className="clearfix">
                                                    <li><a href="/#"><span className="fa fa-facebook-f"></span></a></li>
                                                    <li><a href="/#"><span className="fa fa-instagram"></span></a></li>
                                                    <li><a href="/#"><span className="fa fa-linkedin"></span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="footer-column col-md-5 col-sm-6 col-xs-12">
                                    <div className="footer-widget links-widget">
                                        <div className="widget-inner" style={hideEnglish}>
                                            <h3>liens</h3>
                                            <div className="links">
                                                <ul>
                                                    <li><a href="/services-telephone-mobile">Téléphone mobile</a></li>
                                                    <li><a href="/services-internet-haute-vitesse">Internet haute vitesse</a></li>
                                                    <li><a href="/services-centre-achats">Centre d'achats</a></li>
                                                    <li><a href="/a-propos-de-nous">A propos de nous</a></li>
                                                    <li><a href="/Perspective">Perspective</a></li>
                                                    <li><a href="/services">Nos services</a></li>
                                                    <li><a href="/adhesion">Adhésion</a></li>
                                                    <li><a href="/faqs">FAQs</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="widget-inner" style={hideFrench}>
                                            <h3>Links</h3>
                                            <div className="links">
                                                <ul>
                                                    <li><a href="/services-telephone-mobile">Mobile phone</a></li>
                                                    <li><a href="/services-internet-haute-vitesse">High speed internet</a></li>
                                                    <li><a href="/services-centre-achats">Shopping centre</a></li>
                                                    <li><a href="/a-propos-de-nous">About Us</a></li>
                                                    <li><a href="/Perspective">Perspective</a></li>
                                                    <li><a href="/services">Services</a></li>
                                                    <li><a href="/adhesion">Become a member</a></li>
                                                    <li><a href="/faqs">FAQs</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="big-column col-md-6 col-sm-12 col-xs-12">
                            <div className="row clearfix">
                                <div className="footer-column col-md-8 col-sm-8 col-xs-12">
                                    <div className="footer-widget info-widget">
                                        <div className="widget-inner">
                                            <h3>Contact</h3>
                                            <div className="info" style={hideEnglish}>
                                                <ul>
                                                    <li className="clearfix"><strong>Adresse : </strong> <span className="txt">200 - 4388 Saint Denis, Montréal (Quebec) H2J2L1</span></li>
                                                    <li className="clearfix"><strong>Téléphone : </strong> <span className="txt">+1844 780 7013</span></li>
                                                    <li className="clearfix"><strong>Courriel: </strong> <span className="txt">info@coopexa.com</span></li>
                                                </ul>
                                            </div>
                                            <div className="info" style={hideFrench}>
                                                <ul>
                                                    <li className="clearfix"><strong>Address : </strong> <span className="txt">200 - 4388 Saint Denis, Montreal (Quebec) H2J2L1</span></li>
                                                    <li className="clearfix"><strong>Phone : </strong> <span className="txt">+1844 780 7013</span></li>
                                                    <li className="clearfix"><strong>Email: </strong> <span className="txt">info@coopexa.com</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-column col-md-4 col-sm-4 col-xs-12">
                                    <div className="footer-widget gallery-widget">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="copyright" style={hideEnglish}>&copy; Coopexa &nbsp; Numéro de licence CRTC : 8190-C362-202106468</div>
                            <div className="copyright" style={hideFrench}>&copy; Coopexa &nbsp; CRTC license number : 8190-C362-202106468</div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="footer-nav clearfix" style={hideEnglish}>
                                <ul className="clearfix">
                                <li><a href="/lutte-anti-corruption">Politique anti-corruption</a></li>
                                    <li><a href="/privacy">Politique de confidentialité</a></li>
                                </ul>
                            </div>
                            <div className="footer-nav clearfix" style={hideFrench}>
                                <ul className="clearfix">
                                    <li><a href="/lutte-anti-corruption">Anti-corruption policy</a></li>
                                    <li><a href="/privacy">Privacy policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
     );
    }
}
 
const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);