import React, { Component } from 'react';
import {connect } from 'react-redux';
import Footer from '../components/Footer';
import HeaderLower from '../components/HeaderLower';
import HeaderUpper from '../components/HeaderUpper';

class Adhesion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname:'',
            address : '',
            country : '',
            city : '',
            profession: '',
            email : '',
            phone : '',
            membership : '',
            error : false,
            show : true,
            errorMessage:'',
            successMessage : ''
        }

    }
  
    handleFirstname = (event) => {
        this.setState({firstname : event.target.value});
        if (event.target.value.length < 2 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleLastname = (event) => {
        this.setState({lastname : event.target.value});
        if (event.target.value.length < 3 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleCountry = (event) => {
        this.setState({country : event.target.value});
        if (event.target.value.length < 5 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleCity = (event) => {
        this.setState({city : event.target.value});
        if (event.target.value.length < 3 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleEmail = (event) => {
        this.setState({email : event.target.value});
        if (event.target.value.length > 0  && (event.target.value.split("").filter(x => x === "@").length !== 1 || (event.target.value.indexOf(".") === -1) || (event.target.value.length - event.target.value.indexOf(".") < 3))) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleMembership = (event) => {
        this.setState({membership : event.target.value});
        if (event.target.value.length < 1 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleProfession = (event) => {
        this.setState({profession : event.target.value});
        if (event.target.value.length < 3 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handlePhone = (event) => {

        this.setState({phone : event.target.value});
        if (event.target.value.length < 8 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleAddress = (event) => {

        this.setState({address : event.target.value});
        if (event.target.value.length < 5 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleSumit = (e) => {
        e.preventDefault();
        this.setState({errorMessage : ''});
        this.setState({successMessage : ''});
        const data = {
            firstname : this.state.firstname,
            lastname : this.state.lastname,
            address : this.state.address,
            email : this.state.email,
            city : this.state.city,
            country : this.state.country,
            phone : this.state.phone,
            profession : this.state.profession,
            statutmembership: this.state.membership,
            language : this.props.language
        };

        if(this.state.error){
            const str = (this.props.language !== 'English') ? 'Validation error' : 'Une erreur de validation';
            this.setState({errorMessage : str});
            this.setState({show : true});
            //console.log(this.state.errorMessage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return false;
        }
        
        const endpoint = '//coopexa.com/api/register.php';
    
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            
            if(response.action === "failed"){
                this.setState({errorMessage : response.msg})
                this.setState({show : true})
                document.querySelector('#' + response.field).classList.add('is-invalid');
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return false;
            }else{
                this.setState({successMessage : response.msg})
                this.setState({show : true})
                window.scrollTo({ top: 0, behavior: 'smooth' });

                this.reset();
                return false;
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    reset = () => {
       
        this.setState({firstname : ''});
        this.setState({lastname : ''});
        this.setState({email : ''});
        this.setState({city : ''});
        this.setState({country : ''});
        this.setState({phone : ''});
        this.setState({profession : ''});
        this.setState({membership : ''});
       
    }

    render() {
        const showMessage = {
            display : (this.state.show) ? "block" : "none"
        }
        const showSuccess = {
            display : (this.state.successMessage !== '') ? "block" : "none"
        }

        const showError = {
            display : (this.state.errorMessage !== '') ? "block" : "none"
        }

        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }
    
        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }

        return (
            <div className="App">
                <div className="page-wrapper">
                    <div className="preloader"></div>
                    <header className="main-header header-style-one">
                        <HeaderUpper />
                        <HeaderLower />
                    </header>

                    <section className="about-section">
                        <div className="auto-container">
                            <div className="sec-title centered" style={hideEnglish}>
                                <h1>Adhésion</h1>
                            </div>
                            <div className="sec-title centered" style={hideFrench}>
                                <h1>Become a member</h1>
                            </div>

                            <div className="row clearfix">
                                <div style={{margin:'20px'}}>
                                    <div className="contact-area ptb-100 bg-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <div className="logo_coopexa">
                                                        <img src="images/coopexa_logo.png" alt="COOPEXA"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/><br/>
                                            <div>
                                            <div className="row" style={showMessage}>
                                                <div className="alert alert-success" role="alert" style={showSuccess}>
                                                    {this.state.successMessage}
                                                </div>
                                                <div className="alert alert-danger" role="alert" style={showError}>
                                                    {this.state.errorMessage}
                                                </div>
                                            </div>
                                                <form action="#" method="post" id="formsignup" style={hideFrench}>
                                                    <div className="row">
                                                        <div className="form-group">
                                                            <label htmlFor="lastname">Last Name: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Last Name" id="lastname" name="lastname" value={this.state.lastname} onChange={this.handleLastname}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="firstname">First Name: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="First Name" id="firstname" name="firstname" value={this.state.firstname} onChange={this.handleFirstname}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="address">Address: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Address" id="address" name="address" value={this.state.address} onChange={this.handleAddress}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="phone">Phone: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Phone" id="phone" name="phone" value={this.state.phone} onChange={this.handlePhone}/>
                                                        </div>
                                                        <div className="form-group">
                                                        <label htmlFor="country">Country: (<span className="requiredfield">*</span>)</label>
                                                        <select className="form-control" id="country" name="country" value={this.state.country} onChange={this.handleCountry}>
                                                            <option value="">Select your country</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="USA">USA</option>
                                                            <option value="France">France</option>
                                                            <option value="Italie">Italy</option>
                                                            <option value="Espagne">Spain</option>
                                                            <option value="Allemagne">Germany</option>
                                                            <option value="Portugal">Portugal</option>
                                                            <option value="Grèce">Greece</option>
                                                            <option value="Belgique">Belgium</option>
                                                            <option value="Royaume-Uni">UK</option>
                                                            <option value="Sénégal">Senegal</option>
                                                        </select>
                                                        </div>
                                                        <br/>
                                                        <div className="form-group">
                                                            <label htmlFor="city">City: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="city" id="city" name="city" value={this.state.city} onChange={this.handleCity}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="profession">Profession:</label>
                                                            <input type="text" className="form-control" placeholder="Profession" id="profession" name="profession" value={this.state.profession} onChange={this.handleProfession}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="email">Email: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Email" id="email" name="email" value={this.state.email} onChange={this.handleEmail}/>
                                                        </div>
                                                       
                                                        <div className="form-group">
                                                            <label htmlFor="membership">Membership type:(<span className="requiredfield">*</span>)</label>
                                                            <select className="form-control" id="membership" name="membership" value={this.state.membership} onChange={this.handleMembership}>
                                                                <option value="">Membership type</option>
                                                                <option value="individual">individual</option>
                                                                <option value="family">family</option>
                                                            </select>
                                                        </div>
                                                        <br/>
                                                        <div className="text-xs-right" style={hideFrench}>
                                                            <button
                                                            type="submit"
                                                            className="btn btn-info rounded-pill px-4" onClick={this.handleSumit}>
                                                            Send
                                                            </button>
                                                        </div>
                                                    </div>
                                            
                                                </form>

                                                <form action="#" method="post" id="formsignup" style={hideEnglish}>
                                                    <div className="row">
                                                        <div className="form-group">
                                                            <label htmlFor="lastname">Nom: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Nom de famille" id="lastname" name="lastname" value={this.state.lastname} onChange={this.handleLastname}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="firstname">Prénom: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Prénom" id="firstname" name="firstname" value={this.state.firstname} onChange={this.handleFirstname}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="address">Adresse: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Address" id="address" name="address" value={this.state.address} onChange={this.handleAddress}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="phone">Téléphone: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Téléphone" id="phone" name="phone" value={this.state.phone} onChange={this.handlePhone}/>
                                                        </div>
                                                        <div className="form-group">
                                                        <label htmlFor="country">Pays: (<span className="requiredfield">*</span>)</label>
                                                        <select className="form-control" id="country" name="country" value={this.state.country} onChange={this.handleCountry}>
                                                            <option value="">Sélectionner votre pays</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="USA">USA</option>
                                                            <option value="France">France</option>
                                                            <option value="Italie">Italie</option>
                                                            <option value="Espagne">Espagne</option>
                                                            <option value="Allemagne">Allemagne</option>
                                                            <option value="Portugal">Portugal</option>
                                                            <option value="Grèce">Grèce</option>
                                                            <option value="Belgique">Belgique</option>
                                                            <option value="Royaume-Uni">Royaume-Uni</option>
                                                            <option value="Sénégal">Sénégal</option>
                                                        </select>
                                                        </div>
                                                        <br/>
                                                        <div className="form-group">
                                                            <label htmlFor="city">Ville: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Ville" id="city" name="city" value={this.state.city} onChange={this.handleCity}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="profession">Profession:</label>
                                                            <input type="text" className="form-control" placeholder="Profession" id="profession" name="profession" value={this.state.profession} onChange={this.handleProfession}/>
                                                        </div>								
                                                        <div className="form-group">
                                                            <label htmlFor="email">Courriel: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Email" id="email" name="email" value={this.state.email} onChange={this.handleEmail}/>
                                                        </div>
                                                       
                                                        <div className="form-group">
                                                            <label htmlFor="membership">Type d’adhésion:(<span className="requiredfield">*</span>)</label>

                                                                <select className="form-control" id="membership" name="membership" value={this.state.membership} onChange={this.handleMembership}>
                                                                    <option value="">Type d'adhésion</option>
                                                                    <option value="individuelle ">individuelle</option>
                                                                    <option value="familiale">familiale</option>
                                                                </select>
                                                        </div>
                                                        <br/>
                                                        
                                                        <div className="text-xs-right">
                                                            <button
                                                            type="submit"
                                                            className="btn btn-info rounded-pill px-4" onClick={this.handleSumit}>
                                                            Envoyer
                                                            </button>
                                                        </div>
                                                    </div>
                                            
                                                </form>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
                <div className="scroll-to-top scroll-to-target" data-target="html">
                    <span className="icon fa fa-angle-double-up"></span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Adhesion);