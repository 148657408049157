import React, { Component } from 'react';
import {connect } from 'react-redux';
import Footer from '../components/Footer';
import HeaderLower from '../components/HeaderLower';
import HeaderUpper from '../components/HeaderUpper';

class Perspective extends Component {
   
    render() {
        
        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }

        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }
        return (
            <div className="App">
                <div className="page-wrapper">
                    <div className="preloader"></div>
                    <header className="main-header header-style-one">
                        <HeaderUpper />
                        <HeaderLower />
                    </header>

                    <section className="about-section">
                        <div className="auto-container">
                            <div className="sec-title centered">
                                <h1>Perspective</h1>
                            </div>

                            <div className="row clearfix" style={hideEnglish}>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="inner">
                                        <div className="text">
                                            Dans le long terme, la COOPEXA compte développer ses activités, dans d’autres pays :
                                        </div>
                                
                                        <div className="text">
                                            - Chaque pays ciblé, mettra en place sa coopérative de consommateurs COOPEXA : Canada, USA, France, Italie, Espagne, Allemagne, Portugal, Grèce, Belgique, Royaume-Uni et Sénégal.
                                        </div>
                                        <div className="text">
                                            - Chaque COOPEXA, dans un pays cible, jouit de son autonomie, conformément aux valeurs et statuts retenus par tous.
                                        </div>
                                        <div className="text">
                                            - Sur des projets d’envergure, le Conseil des présidents est sollicité pour faire appel à la collaboration des autres COOPEXA (voir fig dessous).
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix" style={hideFrench}>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="inner">
                                        <div className="text">
                                        In the future, COOPEXA intends to develop its activities in other countries:
                                        </div>
                                
                                        <div className="text">
                                            - Each target country will set up its COOPEXA consumer cooperative: Canada, USA, France, Italy, Spain, Germany, Portugal, Greece, Belgium, United Kingdom and Senegal.
                                        </div>
                                        <div className="text">
                                            - Each COOPEXA, in a target country, enjoys its autonomy, in accordance with the values and statutes adopted by all.
                                        </div>
                                        <div className="text">
                                            - On large-scale projects, the Council of Presidents is called upon to rule on it (see fig below).
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <div className="row clearfix">
                                <div className="text-center">
                                    <div className="col-xs-12">
                                        <img src="/images/structure-3.jpg" alt=""/>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
                <div className="scroll-to-top scroll-to-target" data-target="html">
                    <span className="icon fa fa-angle-double-up"></span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Perspective);
