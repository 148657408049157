import React from 'react';
import Home from './pages/Home';
import Aboutus from './pages/Aboutus';
import Perspective from './pages/Perspective';
import ServicesInternetHS from './pages/ServicesInternetHS';
import ServicesMobile from './pages/ServicesMobile';
import ServicesCentreAchats from './pages/ServicesCentreAchats';
import Adhesion from './pages/Adhesion';
import Plan from './pages/Plan';
import Faqs from './pages/Faqs';
import Privacy from './pages/Privacy';
import LutteAntiCorruption from './pages/LutteAntiCorruption';


import {
    BrowserRouter as Router,
    Route,
    Switch,
} from "react-router-dom";

function App() {
    return ( 
    <Router>
		<div className = "App" >
		<Switch >
			<Route path = "/" exact component = { Home }/> 
			<Route path = "/a-propos-de-nous" exact component = { Aboutus }/>
			<Route path = "/perspective" exact component = { Perspective}/>
			<Route path = "/services-telephone-mobile" exact component = { ServicesMobile}/>
			<Route path = "/services-internet-haute-vitesse" exact component = { ServicesInternetHS}/>
			<Route path = "/services-centre-achats" exact component = { ServicesCentreAchats}/>
			<Route path = "/adhesion" exact component = { Adhesion}/>
			<Route path = "/plans" exact component = { Plan}/>
			<Route path = "/faqs" exact component = { Faqs}/>
			<Route path = "/privacy" exact component = { Privacy}/>
			<Route path = "/lutte-anti-corruption" exact component = { LutteAntiCorruption}/>
		</Switch> 
		</div> 
	</Router>
    );
}

export default App;