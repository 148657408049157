import React, { Component } from 'react';
import {connect } from 'react-redux';

class HeaderLower extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceMenu : '',
            aboutusMenu : '',
            perspectiveMenu : '',
            memberMenu : '',
            faqsMenu : '',
            plansMenu : ''
        }
    }
    clickOnLanguage = (event) => {
        event.preventDefault();
       
        const lg = event.target.rel;
        if(lg === 'English')
            this.props.onChangeLanguage('ENGLISH');
        else
            this.props.onChangeLanguage('FRENCH');  
            
        //Set cookie
        this.setCookie('language', lg, 1000);
    }

    setCookie = (cname, cvalue, exdays) => {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue.toUpperCase() + ";" + expires + ";path=/";
    }
      
    getCookie = (cname) => {
        const name = cname + "=";
        const ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    componentDidMount() {
        if(this.getCookie('language') !== ""){
            if(this.getCookie('language') === 'FRANÇAIS')
                this.props.onChangeLanguage('FRENCH');
            else
                this.props.onChangeLanguage('ENGLISH');
        }
        else
            this.props.onChangeLanguage('FRENCH');

        const url = window.location.pathname;
        this.setState({serviceMenu : (url.indexOf('services-') !== -1) ? 'current' : ''});
        this.setState({aboutusMenu : (url.indexOf('a-propos-de-nous') !== -1) ? 'current' : ''});
        this.setState({perspectiveMenu : (url.indexOf('perspective') !== -1) ? 'current' : ''});
        this.setState({memberMenu : (url.indexOf('adhesion') !== -1) ? 'current' : ''});
        this.setState({faqsMenu : (url.indexOf('faqs') !== -1) ? 'current' : ''});
        this.setState({plansMenu : (url.indexOf('plans') !== -1) ? 'current' : ''});

        
    }

    render() {
        
        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }

        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }

     
        return (
            <>
            <div className="header-lower">
                <div className="auto-container">
                    <div className="nav-outer clearfix" style={hideEnglish}>
                        <nav className="main-menu">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                            <div className="navbar-collapse collapse clearfix">
                                <ul className="navigation clearfix">
                                    <li className={this.state.aboutusMenu}><a href="/a-propos-de-nous">A propos de nous</a></li>
                                    <li className={this.state.perspectiveMenu}><a href="/perspective">Perspective</a></li>
                                    <li className={"dropdown " + this.state.serviceMenu}><a href="/#">Services</a>
                                        <ul>
                                            <li><a href="/services-telephone-mobile">Téléphone mobile</a></li>
                                            <li><a href="/services-internet-haute-vitesse">Internet haute vitesse</a></li>
                                            <li><a href="/services-centre-achats">Centre d'achats</a></li>
                                        </ul>
                                    </li>
                                    <li className={this.state.memberMenu}><a href="/adhesion">Adhésion</a></li>
                                    {/* <li className={this.state.plansMenu}><a href="/plans">Forfaits</a></li> */}
                                    <li className={this.state.faqsMenu}><a href="/faqs">Faqs</a></li>
                                </ul>
                            </div>
                        </nav>
                        <div className="options-box clearfix">
                            <div className="lang-box">
                                <div className="dropdown">
                                    <ul>
                                        <li><a rel={this.props.language} href="/#" onClick={this.clickOnLanguage}>{this.props.language}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nav-outer clearfix" style={hideFrench}>
                        <nav className="main-menu">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                            <div className="navbar-collapse collapse clearfix">
                                <ul className="navigation clearfix">
                                    <li className={this.state.aboutusMenu}><a href="/a-propos-de-nous">About Us</a></li>
                                    <li className={this.state.perspectiveMenu}><a href="/perspective">Perspective</a></li>
                                    <li className={"dropdown " + this.state.serviceMenu}><a href="/#">Services</a>
                                        <ul>
                                            <li><a href="/services-telephone-mobile">Mobile phone</a></li>
                                            <li><a href="/services-internet-haute-vitesse">High speed internet</a></li>
                                            <li><a href="/services-centre-achats">Shopping centre</a></li>
                                        </ul>
                                    </li>
                                    <li className={this.state.memberMenu}><a href="/adhesion">Become a member</a></li>
                                    {/* <li className={this.state.plansMenu}><a href="/plans">Plans</a></li> */}
                                    <li className={this.state.faqsMenu}><a href="/faqs">Faqs</a></li>
                                </ul>
                            </div>
                        </nav>
                        <div className="options-box clearfix">
                            <div className="lang-box">
                                <div className="dropdown">
                                   
                                    <ul>
                                        <li><a rel={this.props.language} href="/#" onClick={this.clickOnLanguage}>{this.props.language}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky-header">
                <div className="auto-container clearfix">
                    <div className="logo pull-left">
                        <a href="/" className="img-responsive"><img src="images/logo-small.png" alt="" title=""/></a>
                    </div>
                    <div className="right-col pull-right">
                        <nav className="main-menu">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                            <div className="navbar-collapse collapse clearfix">
                                <ul className="navigation clearfix" style={hideEnglish}>
                                    <li className={this.state.aboutusMenu}><a href="/a-propos-de-nous">A propos de nous</a></li>
                                    <li className={this.state.perspectiveMenu}><a href="/perspective">Perspective</a></li>
                                    <li className={"dropdown" + this.state.serviceMenu}><a href="/#">Services</a>
                                        <ul>
                                            <li><a href="/services-telephone-mobile">Téléphone mobile</a></li>
                                            <li><a href="/services-internet-haute-vitesse">Internet haute vitesse</a></li>
                                            <li><a href="services-centre-achats">Centre d'achats</a></li>
                                        </ul>
                                    </li>
                                    <li className={this.state.memberMenu}><a href="/adhesion">Adhésion</a></li>
                                    <li className={this.state.faqsMenu}><a href="/faqs">Faqs</a></li>
                                </ul>
                                <ul className="navigation clearfix" style={hideFrench}>
                                    <li className={this.state.aboutusMenu}><a href="/a-propos-de-nous">About Us</a></li>
                                    <li className={this.state.perspectiveMenu}><a href="/perspective">Perspective</a></li>
                                    <li className={"dropdown" + this.state.serviceMenu}><a href="/#">Services</a>
                                        <ul>
                                            <li><a href="/services-telephone-mobile">Mobile phone</a></li>
                                            <li><a href="/services-internet-haute-vitesse">High speed internet</a></li>
                                            <li><a href="/services-centre-achats">Shopping centre</a></li>
                                        </ul>
                                    </li>
                                    <li className={this.state.memberMenu}><a href="/adhesion">Become a member</a></li>
                                    {/* <li className={this.state.plansMenu}><a href="/plans">Plans</a></li> */}
                                    <li className={this.state.faqsMenu}><a href="/faqs">Faqs</a></li>
                                </ul>
                            </div>
                          
                        </nav>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLower);