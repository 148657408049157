import React, { Component } from 'react';
import {connect } from 'react-redux';
import Footer from '../components/Footer';
import HeaderLower from '../components/HeaderLower';
import HeaderUpper from '../components/HeaderUpper';

class ServicesCentreAchats extends Component {

	render(){

		const hideFrench = {
			display : (this.props.language === 'English') ? "none" : "block"
		}

		const hideEnglish = {
			display : (this.props.language === 'English') ? "block" : "none"
		}

		return (
			<div className="App">
				<div className="page-wrapper">
					<div className="preloader"></div>
					<header className="main-header header-style-one">
						<HeaderUpper />
						<HeaderLower />
					</header>

					<section className="about-section">
						<div className="auto-container">
							<div className="sec-title centered" style={hideEnglish}>
								<h1>Centre d'achats</h1>
							</div>
							<div className="sec-title centered" style={hideFrench}>
								<h1>Purchasing center</h1>
							</div>

							<div className="row clearfix">
								<div className="col-md-12 col-sm-12 col-xs-12">
									<div className="inner">
										
										<div className="text" style={hideEnglish}>
										Sénégal : 
												<ul>
													<li> Groupements d'achats dans toutes les régions : produits alimentaires de base.</li>
												</ul>
										</div>
										<div className="text" style={hideFrench}>
											Senegal : 
												<ul>
													<li> Purchasing groups in all regions: basic food products.</li>
												</ul>
										</div>
									</div>
								</div>
								<div className="col-md-12 col-sm-12 col-xs-12">
									<img src="/images/senboutique.jpeg" alt="senboutique" style={{maxWidth:'350px'}}/>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</div>
				<div className="scroll-to-top scroll-to-target" data-target="html">
					<span className="icon fa fa-angle-double-up"></span>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesCentreAchats);