import React, { Component } from 'react';
import {connect } from 'react-redux';
import Footer from '../components/Footer';
import HeaderLower from '../components/HeaderLower';
import HeaderUpper from '../components/HeaderUpper';

class Plan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname:'',
            province : '',
            email : '',
            plan : '',
            phone : '',
            transfer : '',
            error : false,
            show : true,
            errorMessage:'',
            successMessage : ''
        }

    }
  
    handleFirstname = (event) => {
        this.setState({firstname : event.target.value});
        if (event.target.value.length < 2 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleLastname = (event) => {
        this.setState({lastname : event.target.value});
        if (event.target.value.length < 3 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleProvince = (event) => {
        this.setState({province : event.target.value});
        if (event.target.value.length < 5 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleEmail = (event) => {
        this.setState({email : event.target.value});
        if (event.target.value.length > 0  && (event.target.value.split("").filter(x => x === "@").length !== 1 || (event.target.value.indexOf(".") === -1) || (event.target.value.length - event.target.value.indexOf(".") < 3))) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handleTransfer = (event) => {
        this.setState({transfer : event.target.value});
        if (event.target.value.length < 1 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    handlePhone = (event) => {

        this.setState({phone : event.target.value});
        if (event.target.value.length < 8 && this.state.transfer === 'Yes'){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

   
    handleSumit = (e) => {
        e.preventDefault();
        this.setState({errorMessage : ''});
        this.setState({successMessage : ''});
        const data = {
            firstname : this.state.firstname,
            lastname : this.state.lastname,
            email : this.state.email,
            province : this.state.province,
            plan : this.state.plan,
            phone : this.state.phone,
            transfer : this.state.transfer,
            language : this.props.language
        };

        if(this.state.error){
            const str = (this.props.language !== 'English') ? 'Please check that all required fields are completed' : 'Veuillez verifier que tous les champs obligatoires sont remplis';
            this.setState({errorMessage : str});
            this.setState({show : true});
            //console.log(this.state.errorMessage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return false;
        }
        
        const endpoint = '//coopexa.com/api/plan.php';
    
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            
            if(response.action === "failed"){
                this.setState({errorMessage : response.msg})
                this.setState({show : true})
                document.querySelector('#' + response.field).classList.add('is-invalid');
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return false;
            }else{
                this.setState({successMessage : response.msg})
                this.setState({show : true})
                window.scrollTo({ top: 0, behavior: 'smooth' });

                this.reset();
                return false;
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    handleTransfer = (e) => {
        this.setState({
            transfer: e.target.value
        });
    }

    handlePlan = (e) => {
        this.setState({
            plan: e.target.value
        });
    }


    reset = () => {
       
        this.setState({firstname : ''});
        this.setState({lastname : ''});
        this.setState({email : ''});
        this.setState({city : ''});
        this.setState({country : ''});
        this.setState({phone : ''});
        this.setState({profession : ''});
        this.setState({membership : ''});
       
    }

    render() {
        const showMessage = {
            display : (this.state.show) ? "block" : "none"
        }
        const showSuccess = {
            display : (this.state.successMessage !== '') ? "block" : "none"
        }

        const showError = {
            display : (this.state.errorMessage !== '') ? "block" : "none"
        }

        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }
    
        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }



        return (
            <div className="App">
                <div className="page-wrapper">
                    <div className="preloader"></div>
                    <header className="main-header header-style-one">
                        <HeaderUpper />
                        <HeaderLower />
                    </header>

                    <section className="about-section">
                        <div className="auto-container">
                            <div className="sec-title centered" style={hideEnglish}>
                                <h1>Forfaits</h1>
                            </div>
                            <div className="sec-title centered" style={hideFrench}>
                                <h1>Plans</h1>
                            </div>

                            <div className="row clearfix">
                                <div style={{margin:'20px'}}>
                                    <div className="contact-area ptb-100 bg-2">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <div className="logo_coopexa">
                                                        <img src="images/coopexa_logo.png" alt="COOPEXA"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/><br/>
                                            <div>
                                            <div className="row" style={showMessage}>
                                                <div className="alert alert-success" role="alert" style={showSuccess}>
                                                    {this.state.successMessage}
                                                </div>
                                                <div className="alert alert-danger" role="alert" style={showError}>
                                                    {this.state.errorMessage}
                                                </div>
                                            </div>
                                                <form action="#" method="post" id="formsignup" style={hideFrench}>
                                                    <div className="row">
                                                        <div className="form-group">
                                                            <label htmlFor="firstname">First Name: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="First Name" id="firstname" name="firstname" value={this.state.firstname} onChange={this.handleFirstname}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="lastname">Last Name: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Last Name" id="lastname" name="lastname" value={this.state.lastname} onChange={this.handleLastname}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="email">Email: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Email" id="email" name="email" value={this.state.email} onChange={this.handleEmail}/>
                                                        </div>
                                                        
                                                        <div className="form-group">
                                                            <label htmlFor="province">Province: (<span className="requiredfield">*</span>)</label>
                                                            <select className="form-control" id="province" name="province" value={this.state.province} onChange={this.handleProvince}>
                                                                <option value="">Sélectionner votre province</option>
                                                                <option value="Alberta">Alberta</option>
                                                                <option value="Colombie-britannique">Colombie Britannique</option>
                                                                <option value="Prince-Edward-Island">Prince Edward Island</option>
                                                                <option value="Manitoba">Manitoba</option>
                                                                <option value="New-Brunswick">New Brunswick</option>
                                                                <option value="New-Scotland">New Scotland</option>
                                                                <option value="Ontario">Ontario</option>
                                                                <option value="Quebec">Québec</option>
                                                                <option value="Saskatchewan">Saskatchewan</option>
                                                                <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                                                <option value="Nunavut">Nunavut</option>
                                                                <option value="Northwest Territories">Northwest Territories</option>
                                                                <option value="Yukon">Yukon</option>
                                                            </select>
                                                        </div>
                                                        <br/>
                                                        <div className="form-group">
                                                            <label htmlFor="package">Select your mobile plan: (<span className="requiredfield">*</span>)</label>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="30$/month-20Go" checked={this.state.plan === "30$/month-20Go"} onChange={this.handlePlan}/>
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                    &nbsp;30$/month-20Go
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="35$/month-40Go" checked={this.state.plan === "35$/month-40Go"} onChange={this.handlePlan} />
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                    &nbsp;35$/month-40Go
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="40$/month-50Go" checked={this.state.plan === "40$/month-50Go"} onChange={this.handlePlan} />
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                    &nbsp;40$/month-50Go
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="55$/month-unlimited" checked={this.state.plan === "55$/month-unlimited"} onChange={this.handlePlan} />
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                    &nbsp;55$/month-unlimited
                                                                </label>
                                                            </div>
                                                            
                                                            <br/>
                                                            <div className="form-group">
                                                                <label htmlFor="transfer">Transfer your number ? (Canadian numbers only)</label>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="transfer" value="Yes" checked={this.state.transfer === "Yes"} onChange={this.handleTransfer}/>
                                                                    <label className="form-check-label" htmlFor="transfer">
                                                                        &nbsp;Yes
                                                                    </label>
                                                                </div>
                                                                {this.state.transfer === 'Yes' ? 
                                                                <div className="form-group">
                                                                    <label htmlFor="phonenumber">Phone number to transfer</label>
                                                                    <input type="text" className="form-control" placeholder="Numéro de téléphone" id="phone" name="phone" value={this.state.phone} onChange={this.handlePhone}/>
                                                                </div>
                                                                : ''
                                                                }
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="transfer" value="No" checked={this.state.transfer === "No"} onChange={this.handleTransfer}/>
                                                                    <label className="form-check-label" htmlFor="transfer">
                                                                        &nbsp;No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                    
                                                            <br/>
                                                            <div className="text-xs-right" style={hideFrench}>
                                                                <button
                                                                type="submit"
                                                                className="btn btn-info rounded-pill px-4" onClick={this.handleSumit}>
                                                                Send
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                </form>

                                                <form action="#" method="post" id="formsignup" style={hideEnglish}>
                                                    <div className="row">
                                                        <div className="form-group">
                                                            <label htmlFor="firstname">Prénom: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Prénom" id="firstname" name="firstname" value={this.state.firstname} onChange={this.handleFirstname}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="lastname">Nom de famille: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Nom de famille" id="lastname" name="lastname" value={this.state.lastname} onChange={this.handleLastname}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="email">Courriel: (<span className="requiredfield">*</span>)</label>
                                                            <input type="text" className="form-control" placeholder="Courriel" id="email" name="email" value={this.state.email} onChange={this.handleEmail}/>
                                                        </div>
                                                        
                                                        <div className="form-group">
                                                            <label htmlFor="province">Province: (<span className="requiredfield">*</span>)</label>
                                                            <select className="form-control" id="province" name="province" value={this.state.province} onChange={this.handleProvince}>
                                                                <option value="">Sélectionner votre province</option>
                                                                <option value="Alberta">Alberta</option>
                                                                <option value="Colombie-britannique">Colombie Britannique</option>
                                                                <option value="Prince-Edward-Island">Île-du-Prince-Édouard</option>
                                                                <option value="Manitoba">Manitoba</option>
                                                                <option value="Nouveau-Brunswick">Nouveau Brunswick</option>
                                                                <option value="New-Scotland">Nouvelle Écosse</option>
                                                                <option value="Ontario">Ontario</option>
                                                                <option value="Quebec">Québec</option>
                                                                <option value="Saskatchewan">Saskatchewan</option>
                                                                <option value="Newfoundland and Labrador">Terre-Neuve-et-Labrador</option>
                                                                <option value="Nunavut">Nunavut</option>
                                                                <option value="Northwest Territories">Territoires du Nord Ouest</option>
                                                                <option value="Yukon">Yukon</option>
                                                            </select>
                                                        </div>
                                                        <br/>
                                                        <div className="form-group">
                                                            <label htmlFor="package">Sélectionner votre forfait mobile: (<span className="requiredfield">*</span>)</label>
                                                             <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="30$/mois-20Go" checked={this.state.plan === "30$/mois-20Go"} onChange={this.handlePlan}/>
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                    &nbsp;30$/mois-20Go
                                                                </label>
                                                            </div>
                                                           
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="35$/mois-40Go" checked={this.state.plan === "35$/mois-40Go"} onChange={this.handlePlan} />
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                    &nbsp;35$/mois-40Go
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="40$/mois-50Go" checked={this.state.plan === "40$/mois-50Go"} onChange={this.handlePlan} />
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                    &nbsp;40$/mois-50Go
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="55$/mois-unlimited" checked={this.state.plan === "55$/mois-unlimited"} onChange={this.handlePlan} />
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                    &nbsp;55$/mois-illimité
                                                                </label>
                                                            </div>
                                                            
                                                            <br/>
                                                            <div className="form-group">
                                                                <label htmlFor="transfer">Transférer votre numéro ? (Numéros canadiens seulement)</label>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="transfer" value="Yes" checked={this.state.transfer === "Yes"} onChange={this.handleTransfer}/>
                                                                    <label className="form-check-label" htmlFor="transfer">
                                                                        &nbsp;Oui
                                                                    </label>
                                                                </div>
                                                                {this.state.transfer === 'Yes' ? 
                                                                <div className="form-group">
                                                                    <label htmlFor="phonenumber">Numéro de téléphone à transferer</label>
                                                                    <input type="text" className="form-control" placeholder="Numéro de téléphone" id="phone" name="phone" value={this.state.phone} onChange={this.handlePhone}/>
                                                                </div>
                                                                : ''
                                                                }
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="transfer" value="No" checked={this.state.transfer === "No"} onChange={this.handleTransfer}/>
                                                                    <label className="form-check-label" htmlFor="transfer">
                                                                        &nbsp;Non
                                                                    </label>
                                                                </div>
                                                            </div>
                                                    
                                                            <br/>
                                                            <div className="text-xs-right" style={hideEnglish}>
                                                                <button
                                                                type="submit"
                                                                className="btn btn-info rounded-pill px-4" onClick={this.handleSumit}>
                                                                Soumettre
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                </form>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
                <div className="scroll-to-top scroll-to-target" data-target="html">
                    <span className="icon fa fa-angle-double-up"></span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plan);