import React, { Component } from 'react';
import {connect } from 'react-redux';
import Footer from '../components/Footer';
import HeaderLower from '../components/HeaderLower';
import HeaderUpper from '../components/HeaderUpper';

class Aboutus extends Component {
   
    render() {
        
        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }

        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }
        return (
            <div className="App">
                <div className="page-wrapper">
                    <div className="preloader"></div>
                    <header className="main-header header-style-one">
                        <HeaderUpper />
                        <HeaderLower />
                    </header>

                    <section className="about-section" style={hideFrench}>
                        <div className="auto-container">
                            <div className="sec-title centered">
                                <h1>About Us</h1>
                            </div>

                            <div className="row clearfix">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="inner">
                                        <div className="text">COOPEXA is a legal person whose goal is to meet the common economic, social or cultural needs of the members of the community. It is a consumer cooperative.</div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="inner">
                                        <div className="text"><br/>The Cooperative pursues these objectives:</div>
                                        <div className="text">
                                        <br/>Provide goods and services to its members through a purchasing group:
                                        </div>
                                        <div>
                                            <ul>
                                                <li> - High speed internet services</li>
                                                <li> - Telecommunications services</li>
                                                <li> - Purchasing group</li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="about-section" style={hideEnglish}>
                        <div className="auto-container">
                            <div className="sec-title centered">
                                <h1>A propos de nous</h1>
                            </div>

                            <div className="row clearfix">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="inner">
                                        <div className="text">La Coopérative des expatriés est une personne morale qui a pour but la satisfaction des besoins économiques, sociaux ou culturels communs, des membres de la communauté. Elle est une coopérative de consommateurs.</div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="inner">
                                        <div className="text"><br/>La Coopérative poursuit ces objectifs :</div>
                                        <div className="text">
                                        <br/>Fournir des biens et services à ses membres par le biais d’un regroupement d'achats :
                                        </div>
                                        <div>
                                            <ul>
                                                <li> - Services d’internet à grande vitesse</li>
                                                <li> - Services de télécommunications</li>
                                                <li> - Centrales d'achats</li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
                <div className="scroll-to-top scroll-to-target" data-target="html">
                    <span className="icon fa fa-angle-double-up"></span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Aboutus);
