import React, { Component } from 'react';
import {connect } from 'react-redux';
import Footer from '../components/Footer';
import HeaderLower from '../components/HeaderLower';
import HeaderUpper from '../components/HeaderUpper';

class Faqs extends Component {

	render(){

		const hideFrench = {
			display : (this.props.language === 'English') ? "none" : "block"
		}

		const hideEnglish = {
			display : (this.props.language === 'English') ? "block" : "none"
		}

		const bold = {
			fontWeight:'600'
		}
		return (
			<div className="App">
				<div className="page-wrapper">
					<div className="preloader"></div>
					<header className="main-header header-style-one">
						<HeaderUpper />
						<HeaderLower />
					</header>

					<section className="about-section">
						<div className="auto-container">
							<div className="sec-title centered">
								<h1>FAQs</h1>
								<div className="text" style={hideEnglish}>Foire aux Questions </div>
								<div className="text" style={hideFrench}>Frequently Asked Questions </div>
							</div>

							<div className="row clearfix">
								<div className="row">
									<div className="col-xs-12">
										<div className="section-title text-left mb-40" id="textfaq" style={hideEnglish}>
											<h4 style={bold}>Qu'est-ce qu’une coopérative ?</h4>
											<div className="text">
											Une coopérative est une entreprise qui est la propriété des membres qui utilisent ses services (une coopérative de consommateurs ou une coopérative financière), des personnes qui y travaillent (une coopérative d’employés) ou des personnes qui y habitent (une coopérative d’habitation).  Lorsque différents groupes d’intervenants partagent un intérêt commun pour assurer le succès d’une entreprise, ces groupes peuvent choisir différentes catégories de membres selon les règlements de la coop pour déterminer la façon retenue pour travailler ensemble (une coopérative à intérêts multiples).<br/><br/>
											Les coopératives peuvent offrir presque tout produit ou service et sont différentes des autres entreprises en ce sens que l’utilisation de leurs profits est déterminée par le conseil d’administration et les membres. Il existe des coopératives dans tous les secteurs de l’économie et elles peuvent toucher tous les aspects de nos vies.<br/><br/>
											Les coopératives offrent aux consommateurs une alternative distincte détenue et contrôlée par une communauté et axée sur des valeurs. Contrairement aux secteurs privé, public et bénévole, toutes les coopératives partout au monde sont guidées par les mêmes sept (7) principes :<br/><br/>
											<ul>
												<li>1.	Adhésion volontaire et ouverte </li>
												<li>2.	Contrôle démocratique par les membres </li>
												<li>3.	Participation économique des membres </li>
												<li>4.	Autonomie et indépendance </li>
												<li>5.	Éducation, formation et information </li>
												<li>6.	Coopération entre les membres de la coopérative </li>
												<li>7.	Dévouement à la communauté</li>
											</ul>
											<br/>
											Ces principes font référence à plusieurs types de coopératives. Il s’agira pour nous de mettre en place dans les dix pays ciblés, une coopérative de consommateurs : Canada, USA, France, Italie, Espagne, Allemagne, Portugal, Grèce, Belgique et Royaume-Uni.  <br/><br/>
											La coopérative de consommateurs fournit des biens et services à ses membres pour leur usage personnel. Secteurs: alimentation, épargne et crédit, regroupement d'achats, habitation, placements, investissements, assurance, etc.(Canada.coop)
											</div>
											<br/>

											<h4 style={bold}>Qui peut adhérer à la COOPEXA ?</h4>
											<div className="text">Toute personne qui partage nos valeurs et qui accepte les conditions d’adhésion.
											</div>
											<br/>

											<h4 style={bold}>Comment adhérer à la COOPEXA ?</h4>
											<div className="text">Il faut compléter le formulaire d'adhésion, fournir les pièces demandées et s’acquitter de sa contribution.
											</div>
											<br/>

											<h4 style={bold}>Dans quels secteurs la COOPEXA interviendra ?</h4>
											<div className="text">Il appartient aux membre de déterminer leurs besoins de service les plus communs. 
											</div>
											<br/>

											<h4 style={bold}>Peut-on contribuer au capital de la COOPEXA plus que le minimum annuel requis ?</h4>
											<div className="text">En effet, il appartient aux membres d'y contribuer à leur guise. Les ristournes seront distribuées au prorata des contributions, mais ne donnent pas plus de voix aux élections. 
											</div>
											<br/>

											<h4 style={bold}>Qu’advient-il en cas de décès d’un-e membre ?</h4>
											<div className="text">En cas décès, c’est sa succession qui recevra la totalité de ses contributions.</div>
										</div>
										<div className="section-title text-left mb-40" id="textfaq" style={hideFrench}>
											<h4 style={bold}>What is a cooperative?</h4>
											<div className="text">
											A cooperative is a business owned by the members who use its services (a consumer cooperative or a financial cooperative), the people who work there (an employee cooperative) or the people who live there (a housing cooperative). ). When different stakeholder groups share a common interest in ensuring the success of a business, these groups may choose different categories of members according to the coop's bylaws to determine how to work together (a multi-interest coop).<br/><br/>
											Coops can offer almost any product or service and are different from other businesses in that the use of their profits is determined by the board of directors and members. There are cooperatives in all sectors of the economy and they can touch all aspects of our lives.<br/><br/>
											Cooperatives provide consumers with a distinct, value-driven, community-owned and controlled alternative. Unlike the private, public and voluntary sectors, all cooperatives around the world are guided by the same seven (7) principles:<br/><br/>
											<ul>
												<li>1.	Voluntary and open membership </li>
												<li>2.	Democratic control by members </li>
												<li>3.	Economic participation of members </li>
												<li>4.	Autonomy and independence </li>
												<li>5.	Education, training and information </li>
												<li>6.	Cooperation between members of the cooperative </li>
												<li>7.	Dedication to the community</li>
											</ul>
											<br/>
											These principles refer to several types of cooperatives. For us, this will mean setting up a consumer cooperative in the ten target countries: Canada, USA, France, Italy, Spain, Germany, Portugal, Greece, Belgium and United Kingdom. <br/><br/>
											The consumer cooperative provides goods and services to its members for their personal use. Sectors: food, savings and credit, purchasing groupings, housing, investments, investments, insurance, etc. (Canada.coop)
											</div>
											<br/>

											<h4 style={bold}>Who can join COOPEXA?</h4>
											<div className="text">Anyone who shares our values and accepts the terms of membership.
											</div>
											<br/>

											<h4 style={bold}>How to join COOPEXA?</h4>
											<div className="text">You must complete the membership form, provide the requested documents and pay your contribution.
											</div>
											<br/>

											<h4 style={bold}>In which sectors will COOPEXA operate?</h4>
											<div className="text">It is up to members to determine their most common service needs.
											</div>
											<br/>

											<h4 style={bold}>Can we contribute to the capital of COOPEXA more than the required annual minimum?</h4>
											<div className="text">Indeed, it is up to the members to contribute as they see fit. The rebates will be distributed in proportion to the contributions, but do not give more votes to the elections.
											</div>
											<br/>

											<h4 style={bold}>What happens if a member pass away?</h4>
											<div className="text">In the event of death, his estate will receive all of his contributions.</div>
											<br/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</div>
				<div className="scroll-to-top scroll-to-target" data-target="html">
					<span className="icon fa fa-angle-double-up"></span>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Faqs);