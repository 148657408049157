import React, { Component } from 'react';
import {connect } from 'react-redux';
import HeaderUpper from '../components/HeaderUpper';
import HeaderLower from '../components/HeaderLower';
import Footer from '../components/Footer';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            name: '' ,
            email : '',
            phone : '',
            subject : '',
            message : '',
            successMessage : '',
            errorMessage : '' ,
            show : false     
        };
    }

	render() {     
        
        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }

        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }

  		return (
			<div className="App">
				<div className="page-wrapper">
					<div className="preloader"></div>
					<header className="main-header header-style-one">
						<HeaderUpper />
						<HeaderLower />
					</header>
					<section className="main-slider">
						<div className="rev_slider_wrapper fullwidthbanner-container" id="rev_slider_one_wrapper" data-source="gallery">
							<div className="rev_slider fullwidthabanner" id="rev_slider_one" data-version="5.4.1">
								<ul>
									<li data-description="Slide Description" data-easein="default" data-easeout="default" data-fsmasterspeed="1500" data-fsslotamount="7" data-fstransition="fade" data-hideafterloop="0" data-hideslideonmobile="off" data-index="rs-1687" data-masterspeed="default"
										data-param1="" data-param10="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-rotate="0" data-saveperformance="off" data-slotamount="default" data-thumb="images/main-slider/image-1.jpg"
										data-title="Slide Title" data-transition="parallaxvertical">
										<img alt="" className="rev-slidebg" data-bgfit="cover" data-bgparallax="10" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="images/main-slider/image-1.jpg"/>

										<div className="tp-caption tp-resizeme" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-type="text" data-height="none" data-width="['800','800','800','320']"
											data-whitespace="normal" data-hoffset="['15','15','15','15']" data-voffset="['-80','-80','-20','-40']" data-x="['left','left','left','left']" data-y="['middle','middle','middle','middle']" data-textalign="['top','top','top','top']"
											data-frames='[{"from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
											<h2 style={hideFrench}>Serving members</h2>
											<h2 style={hideEnglish}>Au service des membres</h2>
										</div>

										<div className="tp-caption tp-resizeme" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-type="text" data-height="none" data-width="['600','600','600','320']"
											data-whitespace="normal" data-hoffset="['15','15','15','15']" data-voffset="['10','10','30','30']" data-x="['left','left','left','left']" data-y="['middle','middle','middle','middle']" data-textalign="['top','top','top','top']"
											data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
											<div className="text" style={hideFrench}><br/>Telephony, High Speed Internet and Central Purchasing</div>
											<div className="text" style={hideEnglish}><br/>Téléphonie, Internet Haute Vitesse et Centrales d'achats</div>
										</div>

										<div className="tp-caption tp-resizeme" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-type="text" data-height="none" data-width="['600','600','600','320']"
											data-whitespace="normal" data-hoffset="['15','15','15','15']" data-voffset="['120','120','120','120']" data-x="['left','left','left','left']" data-y="['middle','middle','middle','middle']" data-textalign="['top','top','top','top']"
											data-frames='[{"from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
											<span style={hideFrench}><a href="/plans" className="theme-btn btn-style-one">Choose your plan</a></span>
											<span style={hideEnglish}><a href="/plans" className="theme-btn btn-style-one">Choisissez votre forfait</a></span>
										</div>
										
									</li>
									<li data-description="Slide Description" data-easein="default" data-easeout="default" data-fsmasterspeed="1500" data-fsslotamount="7" data-fstransition="fade" data-hideafterloop="0" data-hideslideonmobile="off" data-index="rs-1688" data-masterspeed="default"
										data-param1="" data-param10="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-rotate="0" data-saveperformance="off" data-slotamount="default" data-thumb="images/main-slider/image-2.jpg"
										data-title="Slide Title" data-transition="parallaxvertical">
										<img alt="" className="rev-slidebg" data-bgfit="cover" data-bgparallax="10" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="images/main-slider/image-2.jpg"/>

										<div className="tp-caption tp-resizeme" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-type="text" data-height="none" data-width="['800','800','800','320']"
											data-whitespace="normal" data-hoffset="['15','15','15','15']" data-voffset="['-80','-80','-20','-40']" data-x="['left','left','left','left']" data-y="['middle','middle','middle','middle']" data-textalign="['top','top','top','top']"
											data-frames='[{"from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
											<h2 style={hideFrench}>Serving members</h2>
											<h2 style={hideEnglish}>Au service des membres</h2>
										</div>

										<div className="tp-caption tp-resizeme" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-type="text" data-height="none" data-width="['600','600','600','320']"
											data-whitespace="normal" data-hoffset="['15','15','15','15']" data-voffset="['10','10','30','30']" data-x="['left','left','left','left']" data-y="['middle','middle','middle','middle']" data-textalign="['top','top','top','top']"
											data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
											<div className="text" style={hideFrench}><br/>Telephony, High Speed Internet and Central Purchasing</div>
											<div className="text" style={hideEnglish}><br/>Téléphonie, Internet Haute Vitesse et Centrales d'achats</div>
										</div>
										<div style={hideFrench} className="tp-caption tp-resizeme" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-type="text" data-height="none" data-width="['600','600','600','320']"
											data-whitespace="normal" data-hoffset="['15','15','15','15']" data-voffset="['120','120','120','120']" data-x="['left','left','left','left']" data-y="['middle','middle','middle','middle']" data-textalign="['top','top','top','top']"
											data-frames='[{"from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
											<a href="/plans" className="theme-btn btn-style-one">Choose your plan</a>
										</div>
										<div className="tp-caption tp-resizeme" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-type="text" data-height="none" data-width="['600','600','600','320']"
											data-whitespace="normal" data-hoffset="['15','15','15','15']" data-voffset="['120','120','120','120']" data-x="['left','left','left','left']" data-y="['middle','middle','middle','middle']" data-textalign="['top','top','top','top']"
											data-frames='[{"from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
											<span style={hideFrench}><a href="/plans" className="theme-btn btn-style-one">Choose your plan</a></span>
											<span style={hideEnglish}><a href="/plans" className="theme-btn btn-style-one">Choisissez votre forfait</a></span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</section>
					<section style={hideFrench}>
                        <div className="auto-container" style={{ margin:'30px auto' }}>
                            <div className="row clearfix centered">
                                <h2 className="iphone13-title">The best plans for iPhone 13</h2>
                            </div>
                            <div className="row clearfix centered">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="iphone13-section">
										<div className="logo"><img src="images/iphone-13.jpg" alt="Iphone 13"/></div>
                                        <div className="text">Our members can buy iPhone 13 of 128gb with an 5GB plan for upfront $399 and $70 per month. You can also buy iPhone mini, iPhone 13 pro or iPhone 13 pro Max with more storage options and different data plans. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
					<section>
						<div className="auto-container" style={{ margin:'30px auto', maxWidth:'500px' }}>
							<Carousel showArrows={true} autoPlay={true} infiniteLoop={true} >
								
								<div>
									<img src="images/phones/iPhone-12-black-01.png" alt="iphone 12"/>
									<p className="legend" style={{ opacity:1, background:'transparent'}}>iPhone 12, 128GB<br/><span style={hideEnglish}>250$ min <br/> 35$/mois en 24 mois</span><span style={hideFrench}>$250 min <br/> $35 / month in 24 months</span></p>
								</div>
								
								<div>
									<img src="images/phones/iPhone13-mini-blue-front.png" alt="iphone 13"/>
									<p className="legend" style={{opacity:1,  background:'transparent'}}>iPhone 13 mini<br/><span style={hideEnglish}>200 $ d’acompte <br/> 38$/mois en 24 mois</span><span style={hideFrench}>$200 down payment <br/> $38 / month in 24 months</span></p>
								</div>
								
								<div>
									<img src="images/phones/Moto-G-Power-front.png" alt="Moto G Power"/>
									<p className="legend" style={{opacity:1,  background:'transparent'}}>Moto-G-Power<br/><span style={hideEnglish}>0$ d’acompte <br/>12$/mois en 24 mois</span><span style={hideFrench}>$0 down payment <br/>$12 / month in 24 months</span></p>
								</div>
								
								<div>
									<img src="images/phones/samsung-s20-fe-navy-front.png" alt="Samsung s20"/>
									<p className="legend" style={{opacity:1,  background:'transparent'}}>Samsung Galaxy S20 FE G5<br/><span style={hideEnglish}>0$ d’acompte<br/>40$/mois en 24 mois</span><span style={hideFrench}>$0 down payment <br/>$40 / month in 24 months</span></p>
								</div>
							</Carousel>
						</div>
					</section>
					<section style={hideEnglish}>
                        <div className="auto-container" style={{ margin:'30px auto' }}>
                            <div className="row clearfix centered">
                                <h2 className="iphone13-title">Les meilleurs plans pour iPhone 13 </h2>
                            </div>
                            <div className="row clearfix centered">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="iphone13-section">
										<div className="logo"><img src="images/iphone-13.jpg" alt="Iphone 13"/></div>
                                        <div className="text">Nos membres peuvent acheter un iPhone 13 de 128 Go avec un forfait de 5 Go pour un montant initial de 399 $ et 70 $ par mois. Vous pouvez également acheter un iPhone mini, un iPhone 13 pro ou un iPhone 13 pro Max avec plus d'options de stockage et différents forfaits de données.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
					
					<Footer />
				</div>
				<div className="scroll-to-top scroll-to-target" data-target="html">
					<span className="icon fa fa-angle-double-up"></span>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);