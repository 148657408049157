import React, { Component } from 'react';
import {connect } from 'react-redux';
import Footer from '../components/Footer';
import HeaderLower from '../components/HeaderLower';
import HeaderUpper from '../components/HeaderUpper';

class LutteAntiCorruption extends Component {
   
    render() {
        
        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }

        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }
        return (
            <div className="App">
                <div className="page-wrapper">
                    <div className="preloader"></div>
                    <header className="main-header header-style-one">
                        <HeaderUpper />
                        <HeaderLower />
                    </header>
                    <section className="about-section" style={hideEnglish}>
                        <div className="auto-container">
                            <div className="sec-title centered">
                                <h1>POLITIQUE DE LUTTE CONTRE LA CORRUPTION</h1>
                            </div>
                            <div>
                                <p>La COOPEXA s'engage à mener ses activités de manière éthique et en conformité avec
                                toutes les lois et réglementations applicables, y compris les lois similaires dans d'autres
                                pays qui interdisent les paiements abusifs pour obtenir un avantage commercial. Ce
                                document décrit la politique de la COOPEXA interdisant les pots-de-vin et autres
                                paiements inappropriés dans la conduite des opérations commerciales de la COOPEXA et
                                les responsabilités des employés pour assurer la mise en œuvre de la politique. Les
                                questions concernant la Politique ou son applicabilité à des circonstances particulières
                                doivent être adressées au Bureau de contrôle de la COOPEXA, info@coopexa.com.</p>

                                <b>APERÇU DE LA POLITIQUE</b>
                                <p>La COOPEXA interdit strictement les pots-de-vin ou autres paiements inappropriés dans
                                toutes ses opérations commerciales. Cette interdiction s'applique à toutes les activités
                                commerciales, partout dans le monde, qu'elles impliquent des représentants du
                                gouvernement ou d'autres entreprises commerciales. Un pot-de-vin ou un autre paiement
                                inapproprié pour obtenir un avantage commercial n'est jamais acceptable et peut exposer
                                les individus et la COOPEXA à d'éventuelles poursuites pénales, à une atteinte à la
                                réputation ou à d'autres conséquences graves. Cette politique s'applique à tout le monde
                                chez la COOPEXA, y compris tous les dirigeants, employés et agents ou autres
                                intermédiaires agissant au nom de la COOPEXA. Chaque dirigeant et employé de la
                                COOPEXA a la responsabilité et l'obligation personnelles de mener les activités
                                commerciales de la COOPEXA de manière éthique et en conformité avec toutes les lois
                                applicables dans les pays dans lesquels la COOPEXA exerce ses activités. Le non-respect
                                de cette obligation peut entraîner des mesures disciplinaires pouvant aller jusqu'au
                                licenciement.</p>

                                <p>
                                Les paiements inappropriés interdits par cette politique comprennent les pots-de-vin, les
                                commissions occultes, les cadeaux ou divertissements excessifs, ou tout autre paiement
                                effectué ou offert pour obtenir un avantage commercial indu. Ces paiements ne doivent
                                pas être confondus avec des dépenses raisonnables et limitées pour des cadeaux, des
                                divertissements professionnels et d'autres activités légitimes directement liées à la
                                conduite des affaires de la COOPEXA.</p>

                                <p>
                                La COOPEXA a développé un programme complet pour la mise en œuvre de cette politique, par le biais d'orientations, de formations, d'enquêtes et surveillance. Le Bureau de contrôle a la responsabilité globale du programme, soutenu par la direction exécutive de COOPEXA. Le Bureau de contrôle est chargé de donner des conseils sur
                                l'interprétation et l'application de cette politique, de soutenir la formation et l'éducation, et
                                de répondre aux préoccupations signalées. L'interdiction de la corruption et d'autres
                                paiements abusifs s'applique à toutes les activités commerciales, mais est
                                particulièrement importante lorsqu'il s'agit de traiter avec des représentants du
                                gouvernement. Les lois sur les pratiques de corruption à l'étranger et des lois similaires
                                dans d'autres pays interdisent strictement les paiements inappropriés pour obtenir un
                                avantage commercial et imposent des sanctions sévères en cas de violation. Le résumé
                                suivant est destiné à fournir au personnel aux partenaires de la COOPEXA une connaissance de base des règles applicables afin que des violations accidentelles ou
                                volontaires puissent être évitées et les problèmes potentiels identifiés à temps pour être
                                correctement traités.</p>
                            </div>
                        </div>
                    </section>
                    <section className="about-section" style={hideFrench}>
                        <div className="auto-container">
                            <div className="sec-title centered">
                                <h1>ANTI-BRIBERY AND CORRUPTION</h1>
                            </div>
                            <div>
                                <b>INTRODUCTION</b>
                                <p>COOPEXA is committed to do its business in an ethical manner and compliance with all
                                applicable laws and regulations, including similar laws in other countries that prohibit
                                abusive payments to gain a business advantage. This document describes COOPEXA's
                                policy prohibiting bribes and other improper payments in the conduct of COOPEXA's
                                business operations and the responsibilities of employees to ensure the implementation of
                                the policy. Questions regarding the Policy or its applicability to particular circumstances
                                should be addressed to the COOPEXA Control Office, info@coopexa.com.</p>
                                <b>POLICY OVERVIEW</b>
                                <p>COOPEXA strictly prohibits bribery or other improper payments in any of its business
                                operations. This prohibition applies to all business activities, anywhere in the world,
                                whether involving government officials or other commercial enterprises. A bribe or other
                                improper payment to secure a business advantage is never acceptable and can expose
                                individuals and COOPEXA to possible criminal prosecution, reputational harm or other
                                serious consequences. This Policy applies to everyone at COOPEXA, including all
                                officers, employees and agents or other intermediaries acting on COOPEXA’s behalf.
                                Each officer and employee of COOPEXA has a personal responsibility and obligation to
                                conduct COOPEXA’s business activities ethically and in compliance with all applicable
                                laws based on the countries wherein COOPEXA does business. Failure to do so may
                                result in disciplinary action, up to and including dismissal.</p>
                                <p>Improper payments prohibited by this policy include bribes, kickbacks, excessive gifts or
                                entertainment, or any other payment made or offered to obtain an undue business
                                advantage. These payments should not be confused with reasonable and limited
                                expenditures for gifts, business entertainment and other legitimate activities directly
                                related to the conduct of COOPEXA’s business.</p>
                                <p>COOPEXA has developed a comprehensive program for the implementation of this
                                policy, through guidance, training, surveys and monitoring. The Control Office has
                                overall responsibility for the program, supported by COOPEXA executive management.
                                The Oversight Office is responsible for advising on the interpretation and application of
                                this policy, supporting training and education, and responding to reported concerns. The
                                prohibition on bribery and other abusive payments applies to all business activities, but is
                                particularly important when it comes to dealing with government officials. Foreign
                                corrupt practices laws and similar laws in other countries strictly prohibit improper
                                payments to gain business advantage and impose severe penalties for violations. The
                                following summary is intended to provide staff in COOPEXA’s partners with a basic
                                knowledge of the applicable rules so that.</p>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
                <div className="scroll-to-top scroll-to-target" data-target="html">
                    <span className="icon fa fa-angle-double-up"></span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LutteAntiCorruption);
