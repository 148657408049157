import React, { Component } from 'react';
import {connect } from 'react-redux';
import Footer from '../components/Footer';
import HeaderLower from '../components/HeaderLower';
import HeaderUpper from '../components/HeaderUpper';

class ServicesMobile extends Component {
   
    render() {
        
        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }

        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }

		const spacelist  = {
            'padding' : '5px 0',
			'textAlign' : 'center',
			'fontSize' : '13px',
			'fontWeight' : '600'
        }

		// const spacelistitalic  = {
        //     'padding' : '0',
		// 	'textAlign' : 'center',
		// 	'fontStyle' : 'italic',
		// 	'fontSize' : '12px'
        // }

		return (
			<div className="App">
				<div className="page-wrapper">
					<div className="preloader"></div>
					<header className="main-header header-style-one">
						<HeaderUpper />
						<HeaderLower />
					</header>
				
					<section className="services-five" style={hideFrench}>
						<div className="auto-container">
							<div className="sec-title centered">
								<h1>Canada</h1>
								<div className="text">Mobile phone with eSIM technology</div>
								<div className="text">Our packages</div>
								{/* <div className="text"><b>Unlimited Nation-wide Calling</b></div>
								<div className="text"><b>Unlimited international calls to 30 countries</b></div>
								<div className="text"><b>Unlimited Nation-wide & International Messages</b></div> */}
							</div>
							
							<div className="row clearfix">
								<div className="service-block-four col-md-3 col-sm-6 col-xs-12">
									<div className="inner-box">
										<figure className="image-box"><img src="images/resource/image-17.jpg" alt=""/></figure>
										<div className="lower-box centered">
											<h4><b>$30 CAD / month / 20 GB</b></h4>
											
											<div className="text">
												<ul style={{listStyleType: 'circle', display:'flex', flexDirection:'column'}}>
													<li style={spacelist}>Unlimited Nation-wide Calling</li>
													<li style={spacelist}>Unlimited international calls to 30 countries</li>
													<li style={spacelist}>Unlimited Nation-wide & International Messages</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
						
								<div className="service-block-four col-md-3 col-sm-6 col-xs-12">
									<div className="inner-box">
										<figure className="image-box"><img src="images/resource/image-17.jpg" alt=""/></figure>
										<div className="lower-box centered">
											<h4><b>$35 CAD / month / 20 GB</b></h4>
											
											<div className="text">
												<ul style={{listStyleType: 'circle', display:'flex', flexDirection:'column'}}>
													
													<li style={spacelist}>Unlimited Nation-wide Calling</li>
													<li style={spacelist}>Unlimited international calls to 30 countries</li>
													<li style={spacelist}>Unlimited Nation-wide & International Messages</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
						
								<div className="service-block-four col-md-3 col-sm-6 col-xs-12">
									<div className="inner-box">
										<figure className="image-box"><img src="images/resource/image-17.jpg" alt=""/></figure>
										<div className="lower-box centered">
											<h4><b>$40 CAD / month / 20 GB</b></h4>

											<div className="text">
												<ul style={{listStyleType: 'circle', display:'flex', flexDirection:'column'}}>
													
													<li style={spacelist}>Unlimited Nation-wide Calling</li>
													<li style={spacelist}>Unlimited international calls to 30 countries</li>
													<li style={spacelist}>Unlimited Nation-wide & International Messages</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="service-block-four col-md-3 col-sm-6 col-xs-12">
									<div className="inner-box">
										<figure className="image-box"><img src="images/resource/image-17.jpg" alt=""/></figure>
										<div className="lower-box centered">
											<h4><b>$55 CAD / month / 20 GB</b></h4>

											<div className="text">
												<ul style={{listStyleType: 'circle', display:'flex', flexDirection:'column'}}>
													
													<li style={spacelist}>Unlimited Nation-wide Calling</li>
													<li style={spacelist}>Unlimited international calls to 30 countries</li>
													<li style={spacelist}>Unlimited Nation-wide & International Messages</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</section>
					<section className="services-five" style={hideEnglish}>
						<div className="auto-container">
							<div className="sec-title centered">
								<h1>Canada</h1>
								<div className="text">Téléphone mobile avec la technologie eSIM</div>
								<div className="text">Nos forfaits</div>
								{/* <div className="text"><b>Appels ILLIMITÉS partout au Canada</b></div>
								<div className="text"><b>Messagerie internationale ILLIMITÉE</b></div> */}
							</div>
							
							<div className="row clearfix">
							<div className="service-block-four col-md-3 col-sm-6 col-xs-12">
									<div className="inner-box">
										<figure className="image-box"><img src="images/resource/image-17.jpg" alt=""/></figure>
										<div className="lower-box centered">
											<h4><b>30$ CAD / mois / 20 GB</b></h4>
											
											<div className="text">
												<ul style={{listStyleType: 'circle', display:'flex', flexDirection:'column'}}>
													<li style={spacelist}>Appels illimités dans tout le pays</li>
													<li style={spacelist}>Appels internationaux illimités vers 30 pays</li>
													<li style={spacelist}>Messages illimités à l'échelle nationale et internationale</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
						
								<div className="service-block-four col-md-3 col-sm-6 col-xs-12">
									<div className="inner-box">
										<figure className="image-box"><img src="images/resource/image-17.jpg" alt=""/></figure>
										<div className="lower-box centered">
											<h4><b>35$ CAD / mois / 40 GB</b></h4>
											
											<div className="text">
												<ul style={{listStyleType: 'circle', display:'flex', flexDirection:'column'}}>
													
													<li style={spacelist}>Appels illimités dans tout le pays</li>
													<li style={spacelist}>Appels internationaux illimités vers 30 pays</li>
													<li style={spacelist}>Messages illimités à l'échelle nationale et internationale</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
						
								<div className="service-block-four col-md-3 col-sm-6 col-xs-12">
									<div className="inner-box">
										<figure className="image-box"><img src="images/resource/image-17.jpg" alt=""/></figure>
										<div className="lower-box centered">
											<h4><b>40$ CAD / mois / 50 GB</b></h4>

											<div className="text">
												<ul style={{listStyleType: 'circle', display:'flex', flexDirection:'column'}}>
													
													<li style={spacelist}>Appels illimités dans tout le pays</li>
													<li style={spacelist}>Appels internationaux illimités vers 30 pays</li>
													<li style={spacelist}>Messages illimités à l'échelle nationale et internationale</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="service-block-four col-md-3 col-sm-6 col-xs-12">
									<div className="inner-box">
										<figure className="image-box"><img src="images/resource/image-17.jpg" alt=""/></figure>
										<div className="lower-box centered">
											<h4><b>55$ CAD / mois / illimité</b></h4>

											<div className="text">
												<ul style={{listStyleType: 'circle', display:'flex', flexDirection:'column'}}>
													
													<li style={spacelist}>Appels illimités dans tout le pays</li>
													<li style={spacelist}>Appels internationaux illimités vers 30 pays</li>
													<li style={spacelist}>Messages illimités à l'échelle nationale et internationale</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section style={hideEnglish}>
						<div className="auto-container">
							<table className="table table-striped">
								{/* <thead>
									<tr>
										<th>Option de données</th>
										<th>1Go</th>
										<th>2Go</th>
										<th>4Go</th>
										<th>6Go</th>
										<th>9Go</th>
										<th>12Go</th>
									</tr>
								</thead> */}
								<tbody>
									<tr>
										<td>Argentina</td>
										<td>Germany</td>
										<td>Mexico</td>
										<td>Spain</td>
									</tr>
									<tr>
										<td>Australia</td>
										<td>Hong Kong</td>
										<td>Netherlands</td>
										<td>Sri Lanka</td>
									</tr>
									<tr>
										<td>Bangladesh</td>
										<td>India</td>
										<td>Nigeria</td>
										<td>Taiwan</td>
									</tr>
									<tr>
										<td>Brazil</td>
										<td>Ireland</td>
										<td>Pakistan</td>
										<td>United Kingdom</td>
									</tr>
									<tr>
										<td>China</td>
										<td>Italy</td>
										<td>Peru</td>
										<td>United States</td>
									</tr>
									<tr>
										<td>Colombia</td>
										<td>Japan</td>
										<td>Philippines</td>
										<td>Vietnam</td>
									</tr>
									<tr>
										<td>Costa Rica</td>
										<td>Korea</td>
										<td>Poland</td>
										<td></td>
									</tr>
									<tr>
										<td>France</td>
										<td>Malaysia</td>
										<td>Singapore</td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
					</section>
					<section style={hideFrench}>
					<div className="auto-container">
							<table className="table table-striped">
								{/* <thead>
									<tr>
										<th>Option de données</th>
										<th>1Go</th>
										<th>2Go</th>
										<th>4Go</th>
										<th>6Go</th>
										<th>9Go</th>
										<th>12Go</th>
									</tr>
								</thead> */}
								<tbody>
									<tr>
										<td>Argentina</td>
										<td>Germany</td>
										<td>Mexico</td>
										<td>Spain</td>
									</tr>
									<tr>
										<td>Australia</td>
										<td>Hong Kong</td>
										<td>Netherlands</td>
										<td>Sri Lanka</td>
									</tr>
									<tr>
										<td>Bangladesh</td>
										<td>India</td>
										<td>Nigeria</td>
										<td>Taiwan</td>
									</tr>
									<tr>
										<td>Brazil</td>
										<td>Ireland</td>
										<td>Pakistan</td>
										<td>United Kingdom</td>
									</tr>
									<tr>
										<td>China</td>
										<td>Italy</td>
										<td>Peru</td>
										<td>United States</td>
									</tr>
									<tr>
										<td>Colombia</td>
										<td>Japan</td>
										<td>Philippines</td>
										<td>Vietnam</td>
									</tr>
									<tr>
										<td>Costa Rica</td>
										<td>Korea</td>
										<td>Poland</td>
										<td></td>
									</tr>
									<tr>
										<td>France</td>
										<td>Malaysia</td>
										<td>Singapore</td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
					</section>
					
					<Footer />
				</div>
				<div className="scroll-to-top scroll-to-target" data-target="html">
					<span className="icon fa fa-angle-double-up"></span>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesMobile);