import React, { Component } from 'react';
import {connect } from 'react-redux';
class HeaderUpper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: '/'
        }
    }

    render(){
        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }

        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }
        return ( 
            <div className="header-upper">
                <div className="auto-container">
                    <div className="clearfix">
                        <div className="logo-box">
                            <div className="logo">
                                <a href = "/"> <img src = "images/logo.png" alt = "" title = "" /></a> 
                            </div> 
                        </div> 
                        <div className="upper-right">
                            <div className="clearfix">
                                <div className="upper-column info-box">
                                    <div className="icon-box"> 
                                        <span className="flaticon-phone-book"></span>
                                    </div>
                                    <ul>
                                        <li> +1844 - 780 - 7013 <br/> info@coopexa.com </li> 
                                    </ul> 
                                </div> 
                                <div className="upper-column info-box" style={hideFrench}>
                                    <div className="icon-box"> 
                                        <span className="flaticon-clock"></span>
                                    </div>
                                    <ul>
                                        <li> Mon - fri 8.00 to 18.00 <br/> Closed Saturday and Sunday </li> 
                                    </ul> 
                                </div> 
                                <div className="upper-column info-box" style={hideEnglish}>
                                    <div className="icon-box"> 
                                        <span className="flaticon-clock"></span>
                                    </div>
                                    <ul>
                                        <li> Lun - Ven 8.00 to 18.00 <br/> Fermé le Samedi et le Dimanche </li> 
                                    </ul> 
                                </div> 
                                <div className="upper-column info-box">
                                    <div className="icon-box"> 
                                        <span className="flaticon-placeholder-2"></span>
                                    </div>
                                    <ul>
                                        <li style={hideFrench}> 200 - 4388 Saint Denis <br/> Montreal(Quebec) H2J 2 L1 </li> 
                                        <li style={hideEnglish}> 200 - 4388 Saint Denis <br/> Montréal(Québec) H2J 2 L1 </li> 
                                    </ul> 
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUpper);