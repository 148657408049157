import React, { Component } from 'react';
import {connect } from 'react-redux';
import Footer from '../components/Footer';
import HeaderLower from '../components/HeaderLower';
import HeaderUpper from '../components/HeaderUpper';

class ServicesInternetHS extends Component {

render(){

	const hideFrench = {
		display : (this.props.language === 'English') ? "none" : "block"
	}

	const hideEnglish = {
		display : (this.props.language === 'English') ? "block" : "none"
	}

	const spacelist_bullet  = {
		'padding' : '5px',
		'listStyleType': 'circle',
		'textAlign' : 'left',
		'fontSize' : '13px',
		'marginLeft' : '65px'
	}

	const nopadding = {
		padding : '0'
	}

	const price = {
		fontSize : '30px',
		background : '#fff',
		padding : '20px',
		fontWeight : 'bold'
	}

	const upto = {
		fontSize:'11px',
		verticalAlign : 'middle'
	}

	const bold = {
		fontWeight : '600'
	}

	return (
		<div className="App">
			<div className="page-wrapper">
				<div className="preloader"></div>
				<header className="main-header header-style-one">
					<HeaderUpper />
					<HeaderLower />
				</header>

				<section className="services-five" style={hideEnglish}>
					<div className="auto-container">
						<div className="sec-title centered">
							<h1>Canada</h1>
							<div className="text"><h4>Internet haute vitesse</h4></div>
							<div className="text">Nos forfaits</div>
						</div>
						
						<div className="row clearfix">
							<div className="service-block-four col-md-4 col-sm-6 col-xs-12">
								<div className="inner-box" style={{ border : '1px solid rgb(217 224 231)' }}>
									
									<div className="lower-box centered" style={nopadding}>
										<div style={{ background: '#f2f9ff', padding:'10px' }}>
											<h2 style={bold}>Cable internet 75</h2>
											<ul style={{'textAlign' : 'left', 'marginLeft':'40px'}}>
												<li><span style={upto}>upto</span> 75M téléchargement</li>
												<li><span style={upto}>upto</span> 10M d'envoi</li>
												<li>Service illimité</li>
											</ul>
										</div>
										<div className="text" style={{ background: '#fff'}}>
											<ul>
												<li style={spacelist_bullet}>7 jours d'Essai Sans Risque</li>
												<li style={spacelist_bullet}>Contrat de 12 mois</li>
												<li style={spacelist_bullet}>Frais d'activation 0 $</li>
												<li style={spacelist_bullet}>Achat du Modem 59 $</li>
											</ul>
											<div style={price}>45$/mois</div>
										</div>
										
									</div>
								</div>
							</div>
					
							<div className="service-block-four col-md-4 col-sm-6 col-xs-12">
								<div className="inner-box" style={{ border : '1px solid rgb(217 224 231)' }}>
									
									<div className="lower-box centered" style={nopadding}>
										<div style={{ background: '#f2f9ff', padding:'10px' }}>
											<h2 style={bold}>Cable internet 300</h2>
											<ul style={{'textAlign' : 'left', 'marginLeft':'40px'}}>
												<li><span style={upto}>upto</span> 300M téléchargement</li>
												<li><span style={upto}>upto</span> 20M d'envoi</li>
												<li>Service illimité</li>
											</ul>
										</div>
										<div className="text" style={{ background: '#fff'}}>
											<ul>
												<li style={spacelist_bullet}>7 jours d'Essai Sans Risque</li>
												<li style={spacelist_bullet}>Contrat de 12 mois</li>
												<li style={spacelist_bullet}>Frais d'activation 0 $</li>
												<li style={spacelist_bullet}>Achat du Modem 59 $</li>
											</ul>
											<div style={price}>65$/mois</div>
										</div>
										
									</div>
								</div>
							</div>
					
							<div className="service-block-four col-md-4 col-sm-6 col-xs-12">
								<div className="inner-box" style={{ border : '1px solid rgb(217 224 231)' }}>
									
									<div className="lower-box centered" style={nopadding}>
										<div style={{ background: '#f2f9ff', padding:'10px' }}>
											<h2 style={bold}>Cable internet 1000</h2>
											<ul style={{'textAlign' : 'left', 'marginLeft':'40px'}}>
												<li><span style={upto}>upto</span> 1000M téléchargement</li>
												<li><span style={upto}>upto</span> 30M d'envoi</li>
												<li>Service illimité</li>
											</ul>
										</div>
										<div className="text" style={{ background: '#fff'}}>
											<ul>
												<li style={spacelist_bullet}>7 jours d'Essai Sans Risque</li>
												<li style={spacelist_bullet}>Contrat de 12 mois</li>
												<li style={spacelist_bullet}>Frais d'activation 0 $</li>
												<li style={spacelist_bullet}>Louer le Modem Gratuitement</li>
											</ul>
											<div style={price}>85$/mois</div>
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="services-five" style={hideFrench}>
					<div className="auto-container">
						<div className="sec-title centered">
							<h1>Canada</h1>
							<div className="text">High speed internet</div>
							<div className="text">Our packages</div>
						</div>
						
						<div className="row clearfix">
							<div className="service-block-four col-md-4 col-sm-6 col-xs-12">
								<div className="inner-box" style={{ border : '1px solid rgb(217 224 231)' }}>
									
									<div className="lower-box centered" style={nopadding}>
										<div style={{ background: '#f2f9ff', padding:'10px' }}>
											<h2 style={bold}>Internet cable 75</h2>
											<ul style={{'textAlign' : 'left', 'marginLeft':'40px'}}>
												<li><span style={upto}>upto</span> 75M downloads</li>
												<li><span style={upto}>upto</span> 10M uploads</li>
												<li>Unlimited Service</li>
											</ul>
										</div>
										<div className="text" style={{ background: '#fff'}}>
											<ul>
												<li style={spacelist_bullet}>7 days Risk Free Trial</li>
												<li style={spacelist_bullet}>12 month contract</li>
												<li style={spacelist_bullet}>Activation Fee 0 $</li>
												<li style={spacelist_bullet}>Modem purchase 59 $</li>
											</ul>
											<div style={price}>45$/month</div>
										</div>
										
									</div>
								</div>
							</div>
					
							<div className="service-block-four col-md-4 col-sm-6 col-xs-12">
								<div className="inner-box" style={{ border : '1px solid rgb(217 224 231)' }}>
									
									<div className="lower-box centered" style={nopadding}>
										<div style={{ background: '#f2f9ff', padding:'10px' }}>
											<h2 style={bold}>Cable internet 300</h2>
											<ul style={{'textAlign' : 'left', 'marginLeft':'40px'}}>
												<li><span style={upto}>upto</span> 300M downloads</li>
												<li><span style={upto}>upto</span> 20M uploads</li>
												<li>Unlimited Service</li>
											</ul>
										</div>
										<div className="text" style={{ background: '#fff'}}>
											<ul>
												<li style={spacelist_bullet}>7 days Risk Free Trial</li>
												<li style={spacelist_bullet}>12 month contract</li>
												<li style={spacelist_bullet}>Activation Fee 0 $</li>
												<li style={spacelist_bullet}>Modem purchase 59 $</li>
											</ul>
											<div style={price}>65$/month</div>
										</div>
										
									</div>
								</div>
							</div>
					
							<div className="service-block-four col-md-4 col-sm-6 col-xs-12">
								<div className="inner-box" style={{ border : '1px solid rgb(217 224 231)' }}>
									
									<div className="lower-box centered" style={nopadding}>
										<div style={{ background: '#f2f9ff', padding:'10px' }}>
											<h2 style={bold}>Cable internet 1000</h2>
											<ul style={{'textAlign' : 'left', 'marginLeft':'40px'}}>
												<li><span style={upto}>upto</span> 1000M downloads</li>
												<li><span style={upto}>upto</span> 30M uploads</li>
												<li>Unlimited Service</li>
											</ul>
										</div>
										<div className="text" style={{ background: '#fff'}}>
											<ul>
												<li style={spacelist_bullet}>7 days Risk Free Trial</li>
												<li style={spacelist_bullet}>12 month contract</li>
												<li style={spacelist_bullet}>Activation Fee 0 $</li>
												<li style={spacelist_bullet}>Rent the modem for free</li>
											</ul>
											<div style={price}>85$/month</div>
										</div>
									
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
			<div className="scroll-to-top scroll-to-target" data-target="html">
				<span className="icon fa fa-angle-double-up"></span>
			</div>
		</div>
	);
	}
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesInternetHS);