import React, { Component } from 'react';
import {connect } from 'react-redux';
import Footer from '../components/Footer';
import HeaderLower from '../components/HeaderLower';
import HeaderUpper from '../components/HeaderUpper';

class Privacy extends Component {
   
    render() {
        
        const hideFrench = {
            display : (this.props.language === 'English') ? "none" : "block"
        }

        const hideEnglish = {
            display : (this.props.language === 'English') ? "block" : "none"
        }
        const underline = {
            textDecoration : 'underline'
        }
        return (
            <div className="App">
                <div className="page-wrapper">
                    <div className="preloader"></div>
                    <header className="main-header header-style-one">
                        <HeaderUpper />
                        <HeaderLower />
                    </header>

                    <section className="about-section" style={hideFrench}>
                        <div className="auto-container">
                            <div className="sec-title centered">
                                <h1>Privacy Policy</h1>
                            </div>
                            <div>
                            This statement describes the information collection process and the privacy and dissemination practices on the COOPEXA platform. This privacy statement demonstrates our firm commitment to protecting your personal information.<br/><br/>

                            COOPEXA's platform may contain links to other websites, COOPEXA is not responsible for the privacy practices or the content of such sites.<br/><br/>
                            <span style={underline}>Private and global information collected by COOPEXA</span> :<br/>
                            PRIVATE INFORMATION: Any information that allows others to communicate with a user other than through COOPEXA or allows the collection of information about the user other than what is displayed on the site. This information includes: email address, IP address, last name, first name and your address.<br/><br/>

                            OVERALL INFORMATION: Information we collect is not personally identifiable, such as browser type and IP address. This information is about visitors to our platform.<br/><br/>
                            <span style={underline}>Use of information obtained by COOPEXA</span> :<br/>
                            PERSONAL INFORMATION: We use email addresses submitted by members when transmitting anonymous emails. Email addresses are not disclosed to the answering machine or any other personal information. We may use a user's email address to send news about our platform or offers that are relevant to our members. The user can choose not to receive emails of this type by indicating not to receive that.<br/><br/>
                            Email addresses may also be used in private correspondence with the user for customer service matters. All other private information submitted by the user is confidential and will not be disclosed except as required by law or to protect COOPEXA members.<br/><br/>
                            PUBLIC INFORMATION: Public information provided by the user is intended to be consulted by owners and subscribers on the site. This information is used by licensees and subscribers for research and comparison purposes.<br/><br/>

                            OVERALL INFORMATION: We use your server, IP address, browser type and related information in the general administration of our platform. This is not personally identifiable information and is not shared with third parties.<br/><br/>
                            <span style={underline}>Chat rooms, bulletin boards and forums</span>:
                            COOPEXA may provide chat rooms to its users. Information that is disclosed in these areas becomes public information and you should exercise caution when deciding to disclose your personal information online.<br/><br/>
                            <span style={underline}>Use of cookies</span> :<br/>
                            We use cookies in our chat rooms and for our own advertising purposes. Please note that advertisers on the COOPEXA site may send cookies to users of the site and COOPEXA does not control who has access to this information.<br/><br/>
                            <span style={underline}>Modify Delete</span> :<br/>
                            COOPEXA gives members the ability to modify or remove their information from our database. Your privacy is one of our top priorities.<br/><br/>
                            If you have any questions regarding COOPEXA's privacy policy, please send them to info@coopexa.com.
                            <br/><br/>
                            </div>
                        </div>
                    </section>
                    <section className="about-section" style={hideEnglish}>
                        <div className="auto-container">
                            <div className="sec-title centered">
                                <h1>Politique de confidentialité</h1>
                            </div>
                            <div>
                            Le présent énoncé décrit le processus de collecte d'informations et les pratiques de confidentialité et de diffusion sur la plateforme de COOPEXA. Cette déclaration de confidentialité  démontre notre engagement ferme à protéger vos informations personnelles.<br/><br/>

                            La plateforme de COOPEXA peut contenir des liens vers d'autres sites Web, la COOPEXA n'est pas responsable des pratiques de confidentialité ou du contenu de ces sites.<br/><br/>

                            <span style={underline}>Informations privées et globales recueillies  par COOPEXA</span> :<br/>

                            RENSEIGNEMENTS PRIVÉS: Toutes informations qui permet aux autres de communiquer avec un utilisateur autre que par COOPEXA ou permet la collecte d'informations sur l'utilisateur autre que ce qui est affiché sur le site. Ces informations incluent : adresse e-mail, adresse IP, nom, prénom et votre adresse.<br/><br/>

                            RENSEIGNEMENTS GLOBAUX: Des informations que nous recueillons ne sont pas personnellement identifiables, tels que le type de navigateur et l'adresse IP. Ces informations sont recueillies sur les visiteurs de notre plateforme.<br/><br/>

                            <span style={underline}>Utilisation des renseignements obtenus par COOPEXA</span> :<br/>

                            RENSEIGNEMENTS PERSONNELS: Nous utilisons les adresses électroniques soumises par les utilisateurs lors de la transmission des courriels anonymes. Les adresses électroniques ne sont pas divulguées au répondeur ni aucune autre information personnelle. Nous pouvons utiliser l'adresse électronique d'un utilisateur pour envoyer des nouvelles concernant notre plateforme ou nos offres qui sont appropriées à nos membres. L'utilisateur peut choisir de ne pas recevoir de courriels de ce type en indiquant de ne pas vouloir recevoir ce type de message.

                            Les adresses courriels peuvent également être utilisées dans une correspondance privée avec l'utilisateur sur les questions de service à la clientèle. Toutes les autres informations privées soumises par l'utilisateur sont confidentielles et ne seront pas divulgués, sauf si c'est requis par la loi ou pour protéger les membres de COOPEXA.<br/><br/>

                            INFORMATION PUBLIQUE: L'information publique fournie par l'utilisateur est destinée à être consultée par les titulaires et les abonnés sur le site. Cette information est utilisée par les titulaires et les abonnés à des fins de recherche et de comparaison.<br/><br/>

                            INFORMATION GLOBALE: Nous utilisons votre serveur, adresse IP, type de navigateur et des informations connexes dans l'administration générale de notre plateforme. Il ne s'agit pas d'informations personnellement identifiables et ne sont pas communiquées à des tiers.<br/><br/>

                            <span style={underline}>Salons de discussion, babillards et forums</span> :<br/>

                            COOPEXA peut fournir des salles de chat à ses utilisateurs. L'information qui est divulguée dans ces zones devient une information publique et vous devez faire preuve de prudence lorsque vous décidez de divulguer vos renseignements personnels en ligne.<br/><br/>

                            <span style={underline}>Utilisation des cookies</span> :<br/>

                            Nous utilisons des cookies dans nos espaces de discussion et des fins publicitaires. Merci de noter que les annonceurs sur le site COOPEXA peuvent envoyer des cookies aux utilisateurs du site et COOPEXA ne contrôle pas ceux qui ont accès à ces informations.<br/><br/>

                            <span style={underline}>Modifier / Supprimer</span> :<br/>

                            COOPEXA donne aux membres la possibilité de modifier ou retirer leurs informations de notre base de données. Votre vie privée est l'une de nos plus grandes priorités.<br/><br/>

                            Si vous avez des questions concernant la politique de confidentialité de COOPEXA, s'il vous plaît envoyez-les à info@coopexa.com.<br/><br/>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
                <div className="scroll-to-top scroll-to-target" data-target="html">
                    <span className="icon fa fa-angle-double-up"></span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language : state.language
    };
};

const mapDispatchToProps = dispatch => {
    return{
        onChangeLanguage: (language) => dispatch({ type: language }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
